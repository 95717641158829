import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import { NUM_COUNTRIES_IN_REG, GBL_NUM_COUNTRIES } from '../../utils/const'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { wrangleSpResponseData } from '../../utils/helpers'
import SpLegend from '../SpLegend'

const Container = styled.div`
  margin-bottom: 10rem;
`

const Chart = styled.figure`
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
`

const BarCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 80px;
  height: 400px;
  margin: 0 10px;
  :nth-child(1) {
    margin-left: 0;
  }
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  opacity: 1;
  color: ${({ labelColor }) =>
    labelColor ? `var(--${labelColor})` : `var(--white)`};
  font-weight: bold;
`

const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  margin: 0 -0.5rem;
  text-align: center;
  line-height: 1.2;
`

const Blank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => `${props.height}`};
  outline: 1px solid var(--lightGray);
  outline-offset: -1px;
  background: ${({ print }) =>
    print
      ? 'none'
      : `linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  )`};
  background-color: ${({ print }) =>
    print ? 'var(--lightestGray)' : 'var(--white)'};
  border: 1px solid var(--lightGray);
`
const SP22Chart = ({ data, region, print }) => {
  const { t } = useTranslation()

  //const formattedData = wrangleSPResponseData(data)
  const formattedData = wrangleSpResponseData(data)
  //const numCountries =
  //region === 'global' ? GBL_NUM_COUNTRIES : NUM_COUNTRIES_IN_REG[region] //number of countries
  return (
    <>
      <Container>
        <SpLegend print={print} />
        <Chart
          role="img"
          alt={t(
            'Stacked bar chart of proportion of countries that have implemented behavioral or social strategies to address under-vaccination.'
          )}
        >
          {formattedData.map((d, i) => (
            <BarCol key={d.year}>
              <Blank height={`${d.nd}%`} print={print}>
                {d.nd > 4 ? `${d.nd.toFixed()}%` : null}
              </Blank>
              <Bar height={`${d.no}%`} color={'paleBlue'} labelColor={'black'}>
                {d.no > 4 ? `${d.no.toFixed()}%` : null}
              </Bar>
              <Bar height={`${d.yes}%`} color={'brightBlue'}>
                {d.yes > 4 ? `${d.yes.toFixed()}%` : null}
              </Bar>
              <BarLabelContainer>
                <BarLabel weight={d.year === 2021 ? 'bold' : 'normal'}>
                  {d.year === 2021 ? t('Baseline') : d.year}
                </BarLabel>
                {/* {!i && <BarLabel weight={'bold'}>{t('Baseline')}</BarLabel>} */}
              </BarLabelContainer>
            </BarCol>
          ))}
        </Chart>
      </Container>
    </>
  )
}

export default SP22Chart
